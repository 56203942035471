import { Box, Button, Stack, Typography } from "@mui/material";
import CustomDialog from "global/components/CustomDialog/CustomDialog";

interface CustomProps {
  handleDialogClose: Function;
  isVisible: boolean;
  handleOnChange: Function;
  id: string;
  isLoading: boolean;
}

const DeleteConfirmationModal = (props: CustomProps) => {
  const getBody = () => {
    return (
      <Box sx={{ width: "100%" }} mt={3}>
        <Typography variant="body1" display="flex">
          This action cannot be undone. Are you sure you want to continue?
        </Typography>
      </Box>
    );
  };

  const getFooter = () => {
    return (
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Button
          id="cancel_comment"
          variant="outlined"
          size="small"
          color="inherit"
          onClick={() => {
            props.handleDialogClose();
          }}
          disabled={props.isLoading}
        >
          Cancel
        </Button>
        <Button
          id="save_add_comment"
          variant="contained"
          size="small"
          onClick={() => {
            props.handleOnChange();
          }}
          disabled={props.isLoading}
        >
          {props.id ? "Delete" : "Erase"}
        </Button>
      </Stack>
    );
  };

  return (
    <CustomDialog
      handleDialogClose={props.handleDialogClose}
      isDialogOpen={props.isVisible}
      body={getBody()}
      footer={getFooter()}
      title={
        <Typography variant="h5">
          {props.id ? "Delete Subcontractor" : "Erase Filled Form"}
        </Typography>
      }
      closeButtonVisibility
      closable
      maxWidth="xs"
    />
  );
};

export default DeleteConfirmationModal;
