import React, { useEffect, useRef, useState } from "react";
import addCandidateStyles from "screens/Applicants/AddApplicant/AddApplicant.styles";
import { getFormattedNumbers, isTruthy } from "helpers/methods";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { CustomInput } from "global/components";
import { CustomContactNumberInput } from "global/components/CustomContactNumberInput/CustomContactNumberInput";
import {
  Poc,
  Project,
  WorkingLocation,
} from "screens/Applicants/applicantTypes";
import Delete from "assets/icons1/DeleteIcon.svg";

interface CustomProps {
  formFields: any;
  setFormFields: Function;
}

const BasicInfo = (props: CustomProps) => {
  const classes = addCandidateStyles;
  const [skills, setSkills] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    props.setFormFields(props.formFields);
  }, [props.formFields]);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    props.setFormFields({
      ...props.formFields,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const handleMaskInputChange = (event: React.ChangeEvent<any>) => {
    const response = getFormattedNumbers(event.target.value);
    props.setFormFields({
      ...props.formFields,
      [event.target.name]: {
        value: response.number,
        error: "",
      },
    });
  };

  const handleAddPoc = () => {
    props.setFormFields({
      ...props.formFields,
      poc: {
        value: [
          ...props.formFields.poc.value,
          {
            name: "",
            email: "",
            designation: "",
            phone: {
              value: "",
              error: "",
            },
          },
        ],
        error: "",
      },
    });
  };

  const handleDeletePoc = (index: number) => {
    const updatedLineItems = [...props.formFields.poc.value];
    updatedLineItems.splice(index, 1);
    props.setFormFields({
      ...props.formFields,
      poc: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleOnChangePOCField = (
    index: number,
    event: React.ChangeEvent<any>
  ) => {
    const updatedLineItems = [...props.formFields.poc.value];
    updatedLineItems[index] = {
      ...updatedLineItems[index],
      [event.target.name]: event.target.value,
    };
    props.setFormFields({
      ...props.formFields,
      poc: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleAddWorkLocation = () => {
    props.setFormFields({
      ...props.formFields,
      workingLocation: {
        value: [
          ...props.formFields.workingLocation.value,
          {
            city: "",
            state: "",
            zip: [],
          },
        ],
        error: "",
      },
    });
  };

  const handleDeleteWorkLocation = (index: number) => {
    const updatedLineItems = [...props.formFields.workingLocation.value];
    updatedLineItems.splice(index, 1);
    props.setFormFields({
      ...props.formFields,
      workingLocation: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleOnChangeWorkLocationField = (
    index: number,
    event: React.ChangeEvent<any>
  ) => {
    const updatedLineItems = [...props.formFields.workingLocation.value];
    updatedLineItems[index] = {
      ...updatedLineItems[index],
      [event.target.name]: event.target.value,
    };
    props.setFormFields({
      ...props.formFields,
      workingLocation: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleKeypress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handlePlusBtnForSkills(index);
    }
  };

  const handlePlusBtnForSkills = (index: number) => {
    if (isTruthy(skills)) {
      const updatedLineItems = [...props.formFields.workingLocation.value];
      updatedLineItems[index] = {
        ...updatedLineItems[index],
        ["zip"]: [...updatedLineItems[index].zip, skills],
      };
      props.setFormFields({
        ...props.formFields,
        workingLocation: {
          value: updatedLineItems,
          error: "",
        },
      });
    }
    setSkills("");
  };

  const handleDeleteSkills = (
    chip: string,
    zipIndex: number,
    lineItemIndex: number
  ) => {
    const updatedLineItems = [...props.formFields.workingLocation.value];
    let skillArray = [...updatedLineItems[lineItemIndex].zip];
    skillArray.splice(skillArray.indexOf(chip), 1);
    updatedLineItems[lineItemIndex].zip = skillArray;
    props.setFormFields({
      ...props.formFields,
      workingLocation: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleAddProject = () => {
    props.setFormFields({
      ...props.formFields,
      xlProject: {
        value: [
          ...props.formFields.xlProject.value,
          {
            number: "",
            location: "",
            name: "",
            isBid: false,
            isWon: false,
          },
        ],
        error: "",
      },
    });
  };

  const handleDeleteProject = (index: number) => {
    const updatedLineItems = [...props.formFields.xlProject.value];
    updatedLineItems.splice(index, 1);
    props.setFormFields({
      ...props.formFields,
      xlProject: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleOnChangeProjectField = (
    index: number,
    event: React.ChangeEvent<any>
  ) => {
    const updatedLineItems = [...props.formFields.xlProject.value];
    updatedLineItems[index] = {
      ...updatedLineItems[index],
      [event.target.name]: event.target.value,
    };
    props.setFormFields({
      ...props.formFields,
      xlProject: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleOnChangeProjectCheckbox = (
    index: number,
    event: React.ChangeEvent<any>
  ) => {
    const updatedLineItems = [...props.formFields.xlProject.value];
    updatedLineItems[index] = {
      ...updatedLineItems[index],
      [event.target.name]: event.target.checked,
    };
    props.setFormFields({
      ...props.formFields,
      xlProject: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const handleOnChangeProjectFieldRating = (
    index: number,
    event: React.ChangeEvent<any>,
    value: number | null
  ) => {
    const updatedLineItems = [...props.formFields.xlProject.value];
    updatedLineItems[index] = {
      ...updatedLineItems[index],
      [event.target.name]: value === null ? 0 : value,
    };
    props.setFormFields({
      ...props.formFields,
      xlProject: {
        value: updatedLineItems,
        error: "",
      },
    });
  };

  const getBasicInfoScreen = () => {
    return (
      <Grid container sx={{ display: "flex" }} spacing={2}>
        <Grid container sx={{ display: "flex" }} spacing={2} mt={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack display={"flex"} flexDirection={"row"}>
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                }}
                variant="rounded"
              >
                1
              </Avatar>
              <Typography
                sx={{ pl: 2, mt: 1 }}
                variant="subtitle1"
                fontSize="18px"
              >
                Basic Details
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CustomInput
              id="name"
              required
              placeHolder="Company Name"
              type="text"
              name="name"
              label="Company Name"
              onChange={handleOnChangeInputField}
              value={props.formFields.name.value}
              propsToInputElement={{ minLength: 1, maxLength: 100 }}
              error={
                !isTruthy(props.formFields.name.value) &&
                props.formFields.name.error
              }
              helperText={props.formFields.name.error}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CustomInput
              id="field"
              required
              placeHolder="Trade"
              type="text"
              name="field"
              label="Trade"
              onChange={handleOnChangeInputField}
              value={props.formFields.field.value}
              propsToInputElement={{ minLength: 0, maxLength: 100 }}
              error={
                !isTruthy(props.formFields.field.value) &&
                props.formFields.field.error
              }
              helperText={props.formFields.field.error}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CustomContactNumberInput
              label="Office Number"
              id="contactNumber"
              value={props.formFields.phone.value}
              placeHolder="Enter Office No"
              onChange={(phone: any) => {
                props.setFormFields({
                  ...props.formFields,
                  phone: {
                    value: phone,
                    error: "",
                  },
                });
              }}
              error={
                isTruthy(props.formFields.phone.error) &&
                props.formFields.phone.error
              }
            />
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex" }} spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CustomInput
              id="email"
              placeHolder="xyz@abc.com"
              type="email"
              name="email"
              label="Bid Email"
              onChange={handleOnChangeInputField}
              value={props.formFields.email.value}
              propsToInputElement={{ minLength: 1, maxLength: 100 }}
              error={props.formFields.email.error}
              helperText={props.formFields.email.error}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <CustomInput
              id="address-1"
              placeHolder="Address"
              type="text"
              name="address"
              label="Address"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      {props.formFields?.address?.value.length} / 100
                    </Typography>
                  </InputAdornment>
                ),
              }}
              error={
                !isTruthy(props.formFields.address.value) &&
                props.formFields.address.error
              }
              helperText={props.formFields.address.error}
              propsToInputElement={{ maxlength: 100 }}
              onChange={handleOnChangeInputField}
              value={props.formFields?.address?.value}
            />
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          sx={{
            mt: 3,
            width: "100%",
            borderStyle: "dashed",
            borderColor: "#f25822",
            // backgroundColor: "#85C935",
          }}
        />

        <Grid container sx={{ display: "flex" }} spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack display={"flex"} flexDirection={"row"}>
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                }}
                variant="rounded"
              >
                2
              </Avatar>
              <Typography
                sx={{ pl: 2, mt: 1 }}
                variant="subtitle1"
                fontSize="18px"
              >
                POC
              </Typography>
            </Stack>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container sx={{ display: "flex", pl: 2 }} spacing={2}>
              {props.formFields?.poc &&
                props.formFields?.poc.value.map((abc: any, index: number) => {
                  return (
                    <Grid
                      container
                      sx={{ display: "flex" }}
                      key={index}
                      spacing={2}
                      mt={1}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomInput
                          id="poc-name"
                          placeHolder="POC Name"
                          type="text"
                          name="name"
                          label="Name"
                          onChange={(e: React.ChangeEvent<any>) => {
                            handleOnChangePOCField(index, e);
                          }}
                          value={abc.name}
                          propsToInputElement={{ minLength: 0, maxLength: 100 }}
                          // error={
                          //   !isTruthy(abc) &&
                          //   props.formFields.field.error
                          // }
                          // helperText={props.formFields.field.error}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomInput
                          id="poc-designation"
                          placeHolder="Designation"
                          type="text"
                          name="designation"
                          label="Designation"
                          onChange={(e: React.ChangeEvent<any>) => {
                            handleOnChangePOCField(index, e);
                          }}
                          value={abc.designation}
                          propsToInputElement={{ minLength: 0, maxLength: 100 }}
                          // error={
                          //   !isTruthy(abc) &&
                          //   props.formFields.field.error
                          // }
                          // helperText={props.formFields.field.error}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomContactNumberInput
                          label="Contact Number"
                          id="contactNumber"
                          value={abc.phone.value.toString()}
                          placeHolder="Enter Contact No"
                          onChange={(phone: any) => {
                            const updatedLineItems = [
                              ...props.formFields.poc.value,
                            ];
                            updatedLineItems[index] = {
                              ...updatedLineItems[index],
                              phone: {
                                value: phone,
                                error: "",
                              },
                            };
                            props.setFormFields({
                              ...props.formFields,
                              poc: {
                                value: updatedLineItems,
                                error: "",
                              },
                            });
                          }}
                          error={isTruthy(abc.phone.error) && abc.phone.error}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomInput
                          id="poc-email"
                          placeHolder="xyz@abc.com"
                          type="email"
                          name="email"
                          label="Email"
                          onChange={(e: React.ChangeEvent<any>) => {
                            handleOnChangePOCField(index, e);
                          }}
                          value={abc.email}
                          propsToInputElement={{ minLength: 1, maxLength: 100 }}
                          // error={props.formFields.email.error}
                          // helperText={props.formFields.email.error}
                        />
                      </Grid>
                      {index === 0 ? null : (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Box
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <img
                              id="deleteIcon"
                              src={Delete}
                              width="25px"
                              height="25px"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                handleDeletePoc(index);
                              }}
                              alt="Delete POC"
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={handleAddPoc}
                startIcon={
                  <Box sx={classes.centerItem}>
                    <AddIcon fontSize="small" />
                  </Box>
                }
              >
                Add POC
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          sx={{
            mt: 3,
            width: "100%",
            borderStyle: "dashed",
            borderColor: "#f25822",
            // backgroundColor: "#85C935",
          }}
        />
        <Grid container sx={{ display: "flex" }} spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack display={"flex"} flexDirection={"row"}>
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                }}
                variant="rounded"
              >
                3
              </Avatar>
              <Typography
                sx={{ pl: 2, mt: 1 }}
                variant="subtitle1"
                fontSize="18px"
              >
                Working Location
              </Typography>
            </Stack>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container sx={{ display: "flex", pl: 2 }} spacing={2}>
              {props.formFields?.workingLocation &&
                props.formFields?.workingLocation.value.map(
                  (abc: WorkingLocation, index: number) => {
                    return (
                      <Grid
                        container
                        sx={{ display: "flex" }}
                        key={index}
                        spacing={2}
                        mt={1}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <CustomInput
                            id="work-city"
                            placeHolder="Work City"
                            type="text"
                            name="city"
                            label="City"
                            onChange={(e: React.ChangeEvent<any>) => {
                              handleOnChangeWorkLocationField(index, e);
                            }}
                            value={abc.city}
                            propsToInputElement={{
                              minLength: 0,
                              maxLength: 100,
                            }}
                            // error={
                            //   !isTruthy(abc) &&
                            //   props.formFields.field.error
                            // }
                            // helperText={props.formFields.field.error}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <CustomInput
                            id="work-state"
                            placeHolder="Work State"
                            type="text"
                            name="state"
                            label="State"
                            onChange={(e: React.ChangeEvent<any>) => {
                              handleOnChangeWorkLocationField(index, e);
                            }}
                            value={abc.state}
                            propsToInputElement={{
                              minLength: 0,
                              maxLength: 100,
                            }}
                            // error={
                            //   !isTruthy(abc) &&
                            //   props.formFields.field.error
                            // }
                            // helperText={props.formFields.field.error}
                          />
                        </Grid>

                        {index === 0 ? null : (
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <img
                                id="deleteIcon"
                                src={Delete}
                                width="25px"
                                height="25px"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  handleDeleteWorkLocation(index);
                                }}
                                alt="Delete Work Location"
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    );
                  }
                )}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={handleAddWorkLocation}
                startIcon={
                  <Box sx={classes.centerItem}>
                    <AddIcon fontSize="small" />
                  </Box>
                }
              >
                Add Work Location
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          sx={{
            mt: 3,
            width: "100%",
            borderStyle: "dashed",
            borderColor: "#f25822",
            // backgroundColor: "#85C935",
          }}
        />
        <Grid container sx={{ display: "flex" }} spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack display={"flex"} flexDirection={"row"}>
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                }}
                variant="rounded"
              >
                4
              </Avatar>
              <Typography
                sx={{ pl: 2, mt: 1 }}
                variant="subtitle1"
                fontSize="18px"
              >
                Projects
              </Typography>
            </Stack>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container sx={{ display: "flex", pl: 2 }} spacing={2}>
              {props.formFields?.xlProject &&
                props.formFields?.xlProject.value.map(
                  (abc: Project, index: number) => {
                    return (
                      <Grid
                        container
                        sx={{ display: "flex" }}
                        key={index}
                        spacing={2}
                        mt={1}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <CustomInput
                            id="project-number"
                            placeHolder="XL Project number"
                            type="text"
                            name="number"
                            label="XL Project Number"
                            onChange={(e: React.ChangeEvent<any>) => {
                              handleOnChangeProjectField(index, e);
                            }}
                            value={abc.number}
                            propsToInputElement={{
                              minLength: 0,
                              maxLength: 100,
                            }}
                            // error={
                            //   !isTruthy(abc) &&
                            //   props.formFields.field.error
                            // }
                            // helperText={props.formFields.field.error}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <CustomInput
                            id="project-name"
                            placeHolder="Project Name"
                            type="text"
                            name="name"
                            label="Project Name"
                            onChange={(e: React.ChangeEvent<any>) => {
                              handleOnChangeProjectField(index, e);
                            }}
                            value={abc.name}
                            propsToInputElement={{
                              minLength: 0,
                              maxLength: 100,
                            }}
                            // error={
                            //   !isTruthy(abc) &&
                            //   props.formFields.field.error
                            // }
                            // helperText={props.formFields.field.error}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <CustomInput
                            id="project-location"
                            placeHolder="Project location"
                            type="text"
                            name="location"
                            label="Project Location"
                            onChange={(e: React.ChangeEvent<any>) => {
                              handleOnChangeProjectField(index, e);
                            }}
                            value={abc.location}
                            propsToInputElement={{
                              minLength: 0,
                              maxLength: 100,
                            }}
                            // error={
                            //   !isTruthy(abc) &&
                            //   props.formFields.field.error
                            // }
                            // helperText={props.formFields.field.error}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <CustomInput
                            id="owner-name"
                            placeHolder="Client/Owner Name"
                            type="text"
                            name="ownerName"
                            label="Client/Owner Name"
                            onChange={(e: React.ChangeEvent<any>) => {
                              handleOnChangeProjectField(index, e);
                            }}
                            value={abc.ownerName}
                            propsToInputElement={{
                              minLength: 0,
                              maxLength: 100,
                            }}
                            // error={
                            //   !isTruthy(abc) &&
                            //   props.formFields.field.error
                            // }
                            // helperText={props.formFields.field.error}
                          />
                        </Grid>
                        <Grid item xl={12} lg={12} md={4} sm={4} xs={4}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={4}
                          >
                            <Stack direction="row" alignItems="center">
                              <Checkbox
                                checked={abc.isBid}
                                name={"isBid"}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleOnChangeProjectCheckbox(index, event);
                                }}
                              />
                              <Typography
                                variant="body2"
                                display="flex"
                                justifyContent="flex-end"
                              >
                                Bid
                              </Typography>
                            </Stack>
                            {/* </Stack> */}
                            {/* <Stack direction="row" alignItems="center"> */}
                            <Stack direction="row" alignItems="center">
                              <Checkbox
                                checked={abc.isWon}
                                name={"isWon"}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleOnChangeProjectCheckbox(index, e);
                                }}
                              />
                              <Typography
                                variant="body2"
                                display="flex"
                                justifyContent="flex-end"
                              >
                                Won
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xl={12} lg={12} md={4} sm={4} xs={4}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={4}
                          >
                            <Stack direction="row" alignItems="center">
                              <Typography component="legend">
                                Price Rating
                              </Typography>
                              <Rating
                                name="priceRating"
                                precision={0.5}
                                value={abc.priceRating}
                                onChange={(event, newValue) => {
                                  handleOnChangeProjectFieldRating(
                                    index,
                                    event,
                                    newValue
                                  );
                                }}
                              />
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <Typography component="legend">
                                Work Rating
                              </Typography>
                              <Rating
                                name="workRating"
                                precision={0.5}
                                value={abc.workRating}
                                onChange={(event, newValue) => {
                                  handleOnChangeProjectFieldRating(
                                    index,
                                    event,
                                    newValue
                                  );
                                }}
                              />
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xl={12} lg={12} md={2} sm={2} xs={2}>
                          <Stack direction="row" alignItems="center"></Stack>
                        </Grid>
                        {index === 0 ? null : (
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <img
                                id="deleteIcon"
                                src={Delete}
                                width="25px"
                                height="25px"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  handleDeleteProject(index);
                                }}
                                alt="Delete Project"
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    );
                  }
                )}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={handleAddProject}
                startIcon={
                  <Box sx={classes.centerItem}>
                    <AddIcon fontSize="small" />
                  </Box>
                }
              >
                Add Project
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return getBasicInfoScreen();
};

export default BasicInfo;
