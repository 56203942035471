import { isTruthy } from "helpers/methods";
import XlProject from "../ViewApplicant/components/XlProject";

const formatAddress = (address: {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
}) => {
  const addressComponents = [
    address?.line1,
    address?.line2,
    address?.city,
    address?.state,
    address?.country,
    address?.pinCode,
  ];
  return addressComponents
    .filter((component) => isTruthy(component))
    .join(", ");
};

export const basicInfoFormInitialState = (
  prevState?: any,
  isParsed?: boolean
) => {
  let poc: any = {
    value: [],
    error: "",
  };

  if (isTruthy(prevState?.poc)) {
    for (const [index, element] of prevState.poc.entries()) {
      poc.value[index] = {
        name: element.name,
        email: element.email,
        designation: element.designation,
        phone: {
          value: element?.phone?.value
            ? `+${element?.phone?.countryCode} ${element?.phone?.value}`
            : isParsed
            ? `+1${element?.phoneNumber}`
            : "",
          error: "",
        },
      };
    }
  } else {
    poc.value.push({
      name: "",
      email: "",
      designation: "",
      phone: {
        value: "",
        error: "",
      },
    });
  }

  return {
    id: {
      value: prevState?._id ?? "",
      error: "",
    },
    name: {
      value: prevState?.name ?? "",
      error: "",
    },
    field: {
      value: prevState?.field ?? "",
      error: "",
    },
    phone: {
      value: prevState?.phone?.value
        ? `+${prevState?.phone?.countryCode} ${prevState?.phone?.value}`
        : isParsed
        ? `+1${prevState?.phoneNumber}`
        : "",
      error: "",
    },
    email: {
      value: prevState?.email ?? "",
      error: "",
    },
    address: {
      value: prevState?.address ?? "",
      error: "",
    },
    poc,
    workingLocation: {
      value: isTruthy(prevState?.workingLocation)
        ? prevState?.workingLocation
        : [
            {
              city: "",
              state: "",
              zip: [],
            },
          ],
      error: "",
    },
    xlProject: {
      value: isTruthy(prevState?.xlProject)
        ? prevState?.xlProject
        : [
            {
              number: "",
              location: "",
              name: "",
              ownerName: "",
              priceRating: 0,
              workRating: 0,
              isBid: false,
              isWon: false,
            },
          ],
      error: "",
    },
  } as any;
};

export const preferencesFormInitialState = (
  prevState?: any,
  isParsed?: boolean
) => {
  return {
    payScale: {
      value: prevState?.expectedSalary ?? "",
      error: "",
    },
    locations: {
      value: prevState?.locations ?? [],
      error: "",
    },
    shiftTiming: { value: prevState?.shifts ?? [], error: "" },
    startTime: {
      value: prevState?.shiftStartTime ?? [],
      error: "",
    },
    experienceYear: {
      value: isParsed
        ? prevState?.experience?.years
        : prevState?.experience?.years ?? "",
      error: "",
    },
    experienceMonth: {
      value: isParsed
        ? prevState?.experience?.months
        : prevState?.experience?.months ?? "",
      error: "",
    },
    comments: {
      value: prevState?.recruitmentComments ?? [],
      error: "",
    },
    speciality: {
      value: isParsed ? prevState?.speciality : prevState?.speciality ?? [],
      error: "",
    },
    subSpeciality: {
      value: isParsed
        ? prevState?.subSpeciality
        : prevState?.subSpeciality ?? [],
      error: "",
    },
    skills: {
      value: isParsed ? prevState?.skills : prevState?.skills ?? [],
      error: "",
    },
  };
};

export const documentsFormInitialState = (
  prevState?: any,
  isParsed?: boolean,
  uploadedFile?: string
) => {
  let documents: any[] = [
    {
      id: {
        value: "",
        error: "",
      },
      type: {
        value: isParsed ? "Professional" : "",
        error: "",
      },
      title: {
        value: isParsed ? "Resume" : "",
        error: "",
      },
      uploadFile: {
        value: isParsed ? uploadedFile : "",
        error: "",
      },
      comment: {
        value: "",
        error: "",
      },
    },
  ];
  let documentArray: any[] = [];
  if (prevState && !isParsed) {
    for (const document of prevState.documents) {
      documentArray.unshift({
        id: {
          value: document.id ? document.id : "",
          error: "",
        },
        type: {
          value: document.type ? document.type : "",
          error: "",
        },
        title: {
          value: document.title ? document.title : "",
          error: "",
        },
        uploadFile: {
          value: document.path ? document.path : "",
          error: "",
        },
        comment: {
          value: document.comment ?? "",
          error: "",
        },
      });
    }
    documents = documentArray;
    return documents;
  } else {
    return documents;
  }
};
